<template>
  <div class="newscl">
    <!-- header组件     -->
    <vheader :navId="navId"> </vheader>
    <!-- header组件     -->

    <!-- banner组件 -->
    <!-- <banner :navName="navName" :navUrl="navUrl"></banner> -->
    <div class="banner-box">
      <div class="banner-img"></div>
      <div class="banner-nav">
        <div class="banner-nav-content">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <span><i class="el-icon-location"></i>当前位置：</span>
            <el-breadcrumb-item :to="{ path: 'index' }" title="业务入口">网站首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'mediaCoverage' }" title="媒体报道">媒体报道</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'newscl1' }" title="新闻详情">新闻详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <!-- banner组件 -->
    <div class="newscl_w">
      <div class="newsContentBoxLeft">
        <h1 class="newsContentBoxLeftTitle" id="title">恒安康泰献礼西部民生，北京三甲名医问诊福利开启</h1>
        <p class="newsContentBoxLeftTime">
          <span>发布时间：2021-09-22 16:19:50</span><span>阅读：206</span><span>来自：恒安康泰新闻中心</span><span>编辑：刘春梅</span>
        </p>
        <hr class="newsContentBoxLeftFenxian">
        <div id="frameContent">
          <p style="line-height: 1.75em;">
            关爱西部企事业人医疗健康，鹿勤集团与恒安康泰携手为内蒙古地区企事业职工及家庭健康保驾护航。恒安康泰（北京）医疗健康科技将北京优质医疗资源转化为每一个西部家庭的健康守护，通过鹿勤集团送到每一位职工手中。</p>
          <p>&nbsp;</p>
          <p>献礼中秋，恒安康泰全体成员为您及家人送上健康守护，北京三甲医院中秋问诊体验马上开启！</p>
          <p><br></p>
          <p style="text-align: center;"><img src="../../assets/img/mediaCoverage/1632297701.png" title="1632297701.png"
              alt="图片1.png" width="435" height="261"></p>
          <p>&nbsp;</p>
          <p>亚健康状态也是生活小困扰，每张恒安康泰体验卡拥有一次病历咨询机会，北京权威医师为您进行问诊分析，提供权威解答。</p>
          <p>&nbsp;</p>
          <p style="line-height: 1.75em;">
            按照“体验卡使用流程”，完成卡号认证，上传您要咨询的病例检测单或报告单，提交患者信息及问诊内容，恒安康泰将为您直接联络北京三甲医院医师进行咨询服务。诊疗建议将经过整理进行反馈。</p>
          <p style="line-height: 1.75em;"><br></p>
          <p style="line-height: 1.75em; text-align: center;"><img src="../../assets/img/mediaCoverage/1632297738.png"
              title="1632297738.png" alt="图片2.png" width="435" height="290"></p>
          <p style="line-height: 1.75em;"><br></p>
          <p>体验卡使用流程如下：</p>
          <p><strong>（一）通过电脑端/手机端进行问诊服务使用</strong></p>
          <p>1、网页搜索“恒安康泰医疗健康管理”或点击恒安康泰官方网址：www.henganmedicare.com，进入会员首页。点击进入“中秋互动”专题。</p>
          <p>2、输入体验卡唯一卡号，核验成功后，即可上传病历，包括病例单、检验单、诊断报告等。</p>
          <p>3、按要求填写完信息后确认提交，客服（客服微信号3189594）会微信和您确认，并将北京三甲医院医师的咨询结果整理转达。</p>
          <p>&nbsp;</p>
          <p><strong>（二）通过微信公众号进行问诊使用</strong></p>
          <p>1、微信搜索“恒安康泰医疗健康”或扫描二维码（下图一）关注公众号。</p>
          <p>2、公众号内留言“中秋体验”，在回复的链接进行核验。核验成功后，将会收到链接通道。</p>
          <p>3、打开链接，填写您的信息并上传病历，包括病例单、检验单、诊断报告等。</p>
          <p>4、按要求填写完信息后确认提交，您会收到公众号通知确认，并将北京三甲医院医师的咨询结果整理转达给您。</p>
          <p>&nbsp;</p>
          <p><strong>（三）通过对话恒安康泰微信客服进行问诊</strong></p>
          <p>1、添加恒安康泰在线客服微信（3189594）或扫描二维码（下图二）关注微信在线客服</p>
          <p>2、发送“中秋体验+卡号”至客服，客服核验成功后将会为您发送链接通道。</p>
          <p>3、打开链接，按照要求填写信息并上传病历，包括病例单、检验单、诊断报告等。</p>
          <p style="text-align: left;">4、按要求填写完信息后确认提交，客服会将北京三甲医院医师的咨询结果整理，以微信方式告知您。</p>
          <p><br></p>
          <table style="margin:auto;">
            <tbody>
              <tr class="firstRow">
                <td width="242" valign="top" style="word-break: break-all;"><img
                    src="../../assets/img/mediaCoverage/1632297864.jpg" class="erweima_border" title="1632297864.jpg"
                    alt="微信公众号.jpg" width="224" height="205"></td>
                <td width="215" valign="top" style="word-break: break-all;"><img class="erweima_border"
                    src="../../assets/img/mediaCoverage/1632297895.jpg" title="1632297895.jpg" alt="qiyekefu.jpg"
                    width="200" height="204"></td>
              </tr>
              <tr>
                <td width="242" valign="top" align="center" style="word-break: break-all;">（图一公众号）</td>
                <td width="215" valign="top" align="center" style="word-break: break-all;">（图二微信客服）</td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
          <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
          <p><br></p>
        </div>
      </div>
      <div class="listNewsRight">
        <h2 class="stateNewsRighth2">健康热点资讯</h2>
        <hr class="stateNewsRight_h2_hr" />
        <h3 class="stateNewsRighth3">
          <a href="" title="国务新闻">国务新闻</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[0]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <!-- <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu1.jpg" alt="" title="恒安康泰医疗" /></a> -->
        <h3 class="stateNewsRighth3">
          <a href="" title="名医出诊">名医出诊</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[1]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康养生">健康养生</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[2]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu2.jpg" alt="" title="恒安康泰医疗" /></a>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康资讯">健康资讯</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[3]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
      </div>
    </div>
    <vfooter></vfooter>
    <GoTop />
  </div>
</template>
<script>
// import banner from "../../components/banner.vue";
import vheader from "../../components/vheader.vue";
import vfooter from "../../components/vfooter.vue";
import GoTop from "@/components/GoTop.vue";
export default {
  name: 'newscl4',
  data() {
    return {
      navName: '新闻详情',
      navUrl: 'newscl4',
      navId: 5,
      rilist1: [
        [
          { listit: "2020年全国居民健康素养水平达23.15%", lishref: "#" },
          { listit: "癌症治疗新希望！我国首个自主研发...", lishref: "#" },
          { listit: "国家卫健委出台人感染动物源性流感...", lishref: "#" },
          { listit: "国家医保局发文，明确推进门诊费用...", lishref: "#" },
          { listit: "国家卫健委：加强儿童中医药服务 推...", lishref: "#" },
          { listit: "这家西南的医院用高品质医疗服务，建...", lishref: "#" },
          { listit: "“十四五”残疾人康复服务蓝图绘就", lishref: "#" },
          { listit: "《2021医生互联网诊疗行为洞察报告》...", lishref: "#" },
          { listit: "六大方面布局！国办发文推动公立医院...", lishref: "#" },
          { listit: "七大城市用户调研指出：智慧医疗未来...", lishref: "#" },
        ],
        [
          { listit: "中国人民解放军总医院院士合集（部分）", lishref: "#" },
          { listit: "U医公益行启动，让泌尿疾病患者在基层...", lishref: "#" },
          { listit: "第十八期“院士+”西部儿科医师培训班座...", lishref: "#" },
          { listit: "福建首例机器人无腹部切口大肠癌手术在...", lishref: "#" },
          { listit: "多位专家赴湖北十堰参加燃气爆炸紧急医...", lishref: "#" },
          { listit: "张楠：阿尔茨海默病患者护理更需专业指导", lishref: "#" },
          { listit: "第一届智慧医疗与医学人文高峰论坛暨医...", lishref: "#" },
          { listit: "提醒身边长痣的朋友，出现以下五种症状...", lishref: "#" },
          { listit: "“疫”路同行的夫妻档", lishref: "#" },
          { listit: "与北京社团合作，中国西部城市乌兰察布...", lishref: "#" },
        ],
        [
          { listit: "水培蔬菜社交网络走红 专家：较土培更...", lishref: "#" },
          { listit: "山药的做法 山药的功效与作用", lishref: "#" },
          { listit: "发青的鸡蛋黄致癌？ 变绿部分无毒，可...", lishref: "#" },
          { listit: "经常失眠，可能是体内缺什么？3种营养...", lishref: "#" },
          { listit: "你知道健康吃饭的黄金标准吗？", lishref: "#" },
          { listit: "5大习惯能『防癌』，很多人都忽视了", lishref: "#" },
          { listit: "每餐4-6小时的间隔期最符合人体消化需...", lishref: "#" },
          { listit: "晨起一杯水别这么喝，风险太大...教你...", lishref: "#" },
          { listit: "心脏有问题，为什么医生还建议让我多运...", lishref: "#" },
          { listit: "缺铁性贫血，补就完事？补血食物别踩坑", lishref: "#" },
        ],
        [
          { listit: "疾控专家邵一鸣对我国目前疫苗功效给出解读", lishref: "#" },
          {
            listit: "馒头、米饭哪个更容易升血糖？一对比，3餐...",
            lishref: "#",
          },
          { listit: "手抖也能通过外科手术治愈啦", lishref: "#" },
          { listit: "身体血糖高 多喝水好还是少喝水好？看完...", lishref: "#" },
          { listit: "练起来 打太极拳能减“游泳圈”", lishref: "#" },
          { listit: "【科学就医我行动】陈勇皮科问答 | 10.皮...", lishref: "#" },
          { listit: "维生素C有什么功效和副作用？", lishref: "#" },
          {
            listit: "“提高免疫力”，被过度夸大化，免疫平衡比“...",
            lishref: "#",
          },
          { listit: "胃癌如何选择最合适疗方案？CA最新刊文指...", lishref: "#" },
          { listit: "九价HPV疫苗为何“一针难求”", lishref: "#" },
        ],
      ],
    };
  },
  components: {
    // banner,
    vheader,
    vfooter,
    GoTop,
  },
};
</script>
<style lang="less">
@media screen and (min-width:750px) {
  .banner-box {
    .banner-img {
      height: 280Px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 100%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 1200Px;
        margin: auto;

        .el-breadcrumb {
          line-height: 70Px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 20Px;
            margin-right: 12Px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}

@media screen and (max-width:750px) {
  .banner-box {
    .banner-img {
      height: 110px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 200%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 95%;
        margin: auto;

        .el-breadcrumb {
          line-height: 35px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 19px;
            margin-right: 2px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}
</style>